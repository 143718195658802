<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <div v-if="category" class="tgcss-flex tgcss-overflow-x-auto tgcss-flex-nowrap tgcss-py-2 ">
            <ul class="tgcss-p-0 tgcss-w-full tgcss-overflow-x-auto tgcss-pb-3 tgcss-flex !tgcss-m-0">
                <li v-for="(item, index) in category" :key="index"  @click="getSlider(index, item.id,item.type,item.slug,item.name)"
                    class="tgcss-rounded-xl tgcss-px-3 tgcss-cursor-pointer tgcss-text-nowrap tgcss-block tgcss-py-1 tgcss-bg-gray-200 tgcss-ml-1 tgcss-font-semibold"
                    :class="{ 'tgcss-bg-gray-800 tgcss-text-white' : activeTab == index}"
                >{{item.name}}</li>           
            </ul>
        </div>

        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <ul v-if="!loading && slider" class="tgcss-flex tgcss-flex-col tgcss-overflow-y-auto !tgcss-mb-0">
            <div class="tgcss-w-full">
                <router-link  v-for="(item, index) in slider" :key="index" :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="!tgcss-no-underline !tgcss-text-gray-800">
                    <div class="tgcss-flex tgcss-items-start tgcss-border-b tgcss-border-gray-300 tgcss-border-dashed tgcss-w-full tgcss-py-3 tgcss-overflow-hidden"
                    :class="{ ' !tgcss-pt-0 ': index == 0,' !tgcss-border-0 ': index == 4}"
                    >
                        <div class="tgcss-border tgcss-flex tgcss-items-center tgcss-justify-center tgcss-rounded-lg tgcss-overflow-hidden tgcss-h-20 tgcss-min-h-20 tgcss-w-20 tgcss-min-w-20 tgcss-mt-0.5 tgcss-ml-3">
                            <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.image" :alt="item.title">
                        </div>
                        <div class="tgcss-grow">
                            <div class="tgcss-w-full tgcss-h-[62px]">
                                <div class="tgcss-font-semibold tgcss-text-gray-400 tgcss-flex tgcss-items-center tgcss-text-xs2 tgcss-line-clamp-1 tgcss-text-ellipsis tgcss-overflow-hidden">
                                    {{ item.title_second }}
                                </div>
                                <div class="tgcss-font-semibold tgcss-h-12 tgcss-flex tgcss-items-start tgcss-text-sm tgcss-leading-6 tgcss-mb-0.5 tgcss-line-clamp-2 tgcss-text-ellipsis tgcss-overflow-hidden">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="tgcss-text-xs2 tgcss-pr-1 tgcss-font-medium tgcss-text-gray-400 tgcss-flex tgcss-items-center">
                                <router-link :to="{ name: 'ApplicationNews' , params: { category_id: item.category_id, }}"  class="tgcss-py-0.5 tgcss-ml-2 !tgcss-no-underline  -tgcss-mr-1 tgcss-font-semibold tgcss-text-xs2 tgcss-px-2 !tgcss-bg-[#ffefd2] !tgcss-text-[#a68953] tgcss-rounded-full">{{ item.category_title }}</router-link>
                                {{ parseDate(item.jalali_created_at).dayOfWeek }} {{ parseDate(item.jalali_created_at).day }} {{ parseDate(item.jalali_created_at).monthName }} {{ parseDate(item.jalali_created_at).year }}
                                
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>

        </ul>
        <router-link  v-if="!loading && slider && activeType == 'tag'" :to="{ name: 'ApplicationNews' , params: { category_id: activeParam, }, query: { subject:  activeName }}" class="tgcss-opacity-70 tgcss-flex tgcss-rounded-md tgcss-justify-center tgcss-p-2 !tgcss-bg-gray-200  tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800 tgcss-mb-2">مشاهده‌همه</router-link>                
        <router-link  v-if="!loading && slider && activeType == 'category'" :to="{ name: 'ApplicationNews' , params: { category_id: activeId, }, query: { subject:  activeName }}" class="tgcss-opacity-70 tgcss-flex tgcss-rounded-md tgcss-justify-center tgcss-p-2 !tgcss-bg-gray-200  tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800 tgcss-mb-2">مشاهده‌همه</router-link>                

    </div>
</template>
    
<script>
    export default {
        name: 'newsTabs',
        props: ['count','cat'],
        components: {},
        data() {
            return {
                activeId:'',
                activeTab:'',
                activeType:'',
                activeParam:'',
                activeName:'',
                slider:[],
                slider_cat:0,
                loading:false,
                category:[]
            }
        },
        mounted() {
            this.setCat();
            this.getSlider(0,4,'tag','اخبار-ویژه','اخبار ویژه');
        },
        computed: {},
        methods: {
            setCat(){
                this.category = this.cat;
            },
            parseDate(dateString) {
                // تاریخ ورودی: یکشنبه 5 اسفند 1403 ساعت 11:31
                const months = [
                    'فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور',
                    'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'
                ];
                
                // روزهای هفته
                const daysOfWeek = [
                    'شنبه', 'یکشنبه', 'دوشنبه', 'سه‌شنبه', 'چهارشنبه', 'پنج‌شنبه', 'جمعه'
                ];
                
                // جدا کردن روز، ماه، سال
                const dateParts = dateString.match(/(\D+)\s(\d+)\s(\D+)\s(\d+)/);

                const dayOfWeek = dateParts[1];  // روز هفته (فارسی)
                const day = dateParts[2];        // روز
                const month = months.indexOf(dateParts[3]) + 1;  // ماه به شماره
                const monthName = dateParts[3];  // ماه به اسم فارسی
                const year = dateParts[4];       // سال
                
                return { dayOfWeek, day, month, monthName, year };
            },
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getSlider(index,id,type,param,name){
                this.activeId = id;
                this.activeTab = index;
                this.activeType = type;
                this.activeParam = param;
                this.activeName = name;
                this.loading = true;
                this.slider = [];
                var url = '';
                if (type == 'tag') {
                    url = 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+param;
                } else if (type == 'live') {
                    url = 'https://api.tgju.org/v1/news/live?limit='+this.count;
                }else if (type == 'page') {
                    url = '';
                }else if (type == 'category') {
                    url = 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+id;
                }else if (type == 'category-main') {
                    url = 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+id;
                }
                $.ajax({
                    url: url,
                    method: 'get',
                    complete: (res) => {
                        if(type == 'live'){
                            this.slider = res.responseJSON.response.data;   
                        }else{
                            this.slider = res.responseJSON.response.items.data;  
                        }
                        // this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  